import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"

const PageContainer = styled.div`
  display: block;
  width: 100%;
  max-width: 800px;
  text-align: center;
`

const VideoContainer = styled.div`
  margin-bottom: 35px;
  padding: 125.56% 0 0 0;
  position: relative;
`

const Video = styled.iframe`
  border: none;
  border-radius: 10px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const VideoPageTemplate = ({ title, videos }) => {
  return (
    <Layout>
      <PageContainer>
        <SEO title={title} />
        <h1 className="isVisuallyHidden">{title}</h1>
        {videos.map(video => {
          return (
            <VideoContainer key={video.key}>
              <Video
                title={video.name}
                src={video.url}
                frameborder="0"
                allow="autoplay; fullscreen"
                allowFullscreen
              ></Video>
            </VideoContainer>
          )
        })}
        <script src="https://player.vimeo.com/api/player.js"></script>
      </PageContainer>
    </Layout>
  )
}

export default ({ data }) => {
  const { title, videos } = data.sanityVideos

  return <VideoPageTemplate title={title} videos={videos} />
}

export const videoPageQuery = graphql`
  query VideoPage($id: String!) {
    sanityVideos(id: { eq: $id }) {
      id
      title
      videos {
        _key
        name
        url
      }
    }
  }
`
